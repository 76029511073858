//About 
.about-item{
	padding: 10px;
	box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.17);
	-webkit-transition: all 300ms ease-in-out;
	transition: all 300ms ease-in-out;
	.about-text{
		padding: 20px;
		background: #fff;
		h3{
			font-size: 16px;
			text-transform: uppercase;
			font-weight: 500;
			a{
				color: #212121;
				&:hover{
					color: $preset;
				}
			}
		}
		.btn{
			margin-top: 10px;
		}
	}
}
.about-item:hover{
	box-shadow: 1px 3px 15px rgba(0, 0, 0, 0.1);
}

// Event Slides
#event-slides{
	.video{
		img{
			border-radius: 4px;
		}
	}
	.intro-desc{
	    line-height: 26px;
	    margin-bottom: 30px;
	}
	.intro-title{
		color: #212121;
		font-size: 22px;
		font-weight: 700;
		letter-spacing: 1px;
		margin-bottom: 15px;
	}
	.list-specification{
		li{
			margin-bottom: 10px;
			font-size: 15px;
			i{
				margin-right: 5px;
			}
		}
	}
}
// Counter Section
.counter-section{
    background: $preset;
    overflow: hidden;
    position: relative;
    /* 
    &:before{
    	content: '';
    	position: absolute;
    	top: 0;
    	bottom: 0;
    	width: 100%;
    	height: 100%;
    	background: rgba(0, 0, 0, 0.5);
    } */
	.counter {
		padding: 30px 0;
		.icon{
			margin-bottom: 15px;
			i{
			    font-size: 60px;
			    color: #fff;
			}
		}
		p{
			font-size: 15px;
		    color: #fff;
		    font-weight: 700;
		    text-transform: uppercase;
		}
		span{
			color: #fefefe;
			font-size: 12px;
			font-weight: 400;
			text-transform: uppercase;
		}
	}

}

/* Sponsors Area */
#sponsors{
	background: #f3f4f3;
	.spnsors-logo{
		text-align: center;
		border-radius: 4px;
		padding: 50px;
		margin-bottom: 30px;
		display: inline-block;
		border: 1px solid #ddd;
		background: #fff;
		-webkit-transition: all 300ms ease-in-out;
		transition: all 300ms ease-in-out;
	}
	.spnsors-logo:hover{
		border-color: transparent;
		box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.2);
	}
}